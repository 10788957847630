import { createRouter, createWebHistory } from 'vue-router';

// Import your components
import HomePage from '@/views/HomePage.vue';
import LoginPage from '@/views/LoginPage.vue';
import DashboardPage from '@/views/DashboardPage.vue';
import ClassroomPage from "@/views/ClassroomPage.vue";

const routes = [
  { path: '/', name: 'Home', component: HomePage },
  { path: '/login', name: 'Login', component: LoginPage },
  { path: "/classroom", name: "Classroom", component: ClassroomPage },
  { 
    path: '/dashboard', 
    name: 'Dashboard', 
    component: DashboardPage, 
    // meta: { requiresAuth: true }
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Navigation guard (optional)
router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem('authToken'); // Replace with actual auth logic
  if (to.meta.requiresAuth && !isAuthenticated) {
    next('/login');
  } else {
    next();
  }
});

export default router;
