<template>
<div class="container">
  <div class="header">
  <div class="header-left">
    <svg width="98" height="26" viewBox="0 0 98 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.08696 18.0095C5.24545 20.2204 7.43137 21.2713 9.91779 21.2713C11.7195 21.2713 13.1967 21.0292 14.6332 20.5449L15.3534 24.149C13.5783 24.8729 11.4406 25.2329 9.14055 25.2329C3.33467 25.2329 0 21.8519 0 16.5111C0 12.1565 2.71592 7.3418 8.63971 7.3418C14.1945 7.3418 16.293 11.6596 16.293 15.9001C16.3024 16.6079 16.2382 17.3149 16.1015 18.0095H5.08696ZM11.343 14.3598C11.3518 13.0769 10.7787 10.8863 8.35443 10.8863C6.10257 10.8863 5.18459 12.9425 5.04258 14.3598H11.343Z" fill="#0F1424"/>
      <path d="M48.8625 11.6565V7.72665H45.0257V2.83965L39.8132 4.04524V7.72158H36.4215L37.8923 0H32.8713L31.3789 7.72665H31.3447V7.7444C30.6065 7.48028 29.8287 7.34395 29.0447 7.34127C22.563 7.34127 18.2672 12.8279 18.2672 18.7874C18.2672 22.9543 20.6535 25.2526 23.5646 25.2526C25.5705 25.2526 27.6753 24.3653 29.174 22.0834H29.2501L28.9711 25.2286H33.5737C33.7077 22.8738 33.9927 20.5301 34.4271 18.2118L35.6658 11.711L39.817 11.6527V18.6619C39.817 21.0958 40.3014 22.7261 41.2904 23.7415C42.1475 24.6289 43.5841 25.2286 45.3072 25.2286C46.7983 25.2286 48.0713 25.0194 48.7598 24.7709L48.7167 20.7231C48.195 20.8373 47.662 20.8917 47.128 20.8854C45.538 20.8854 45.0245 19.9334 45.0245 17.8657V11.654L48.8625 11.6565ZM29.7674 16.3775C29.1068 19.6545 27.3291 21.1884 25.8736 21.1884C24.418 21.1884 23.5139 20.0272 23.5139 17.9596C23.5139 14.6699 25.6479 11.1875 28.6668 11.1875C29.4986 11.1875 30.2492 11.4499 30.6651 11.7833L29.7674 16.3775Z" fill="#34D375"/>
      <path d="M50.2521 25.2329L52.1844 14.6666C52.6637 12.0881 52.8818 9.20405 53.0606 7.72718H57.4261C57.336 8.88332 57.246 10.0407 57.1129 11.24H57.2232C58.4315 8.95812 60.3347 7.3418 62.6829 7.3418C63.0024 7.3418 63.3296 7.35447 63.6237 7.37983L62.6411 12.2947C62.4204 12.277 62.1415 12.2643 61.8461 12.2643C58.6369 12.2643 57.123 15.128 56.4929 18.4963L55.263 25.2329H50.2521Z" fill="#0F1424"/>
      <path d="M74.7131 25.2329L74.3327 23.1627H74.2198C73.1015 24.5268 71.3518 25.2506 69.3243 25.2506C65.8337 25.2506 63.7834 22.7457 63.7834 20.0074C63.7834 15.5426 67.7787 13.4268 73.842 13.4344V13.2163C73.842 12.3049 73.3526 11.0029 70.7038 11.0093C68.9566 11.0093 67.1207 11.6025 65.9795 12.2884L64.9994 8.87825C66.1925 8.19496 68.5293 7.3418 71.6611 7.3418C77.3668 7.3418 79.1724 10.7139 79.1724 14.7388V20.7148C79.1724 22.3489 79.2827 24.2644 79.4691 25.2329H74.7131ZM74.0005 16.7608C71.1971 16.7456 69.0289 17.4213 69.0289 19.4623C69.0289 20.8416 69.9317 21.5135 71.1438 21.5135C71.7511 21.5131 72.3431 21.3232 72.8373 20.9703C73.3314 20.6173 73.7031 20.119 73.9003 19.5447C73.9751 19.2473 74.0088 18.941 74.0005 18.6345V16.7608Z" fill="#0F1424"/>
      <path d="M81.6298 25.2333L84.9568 7.72757H89.9981L86.6723 25.2333H81.6298ZM85.6808 3.1537C85.6846 1.45244 87.0274 0.298828 88.573 0.298828C90.0565 0.298828 91.1088 1.27496 91.1088 2.78099C91.0949 4.57352 89.7306 5.62318 88.1279 5.62318C86.7066 5.62318 85.6732 4.64959 85.6808 3.1537Z" fill="#34D875"/>
      <path d="M92.6646 0.00585938H98V25.2509H92.6646V0.00585938Z" fill="#0F1424"/>
    </svg>
  </div>
  <div class="header-right">
    <a class="button-round" href="/dashboard">START</a>
  </div>
  <div class="clr"></div>
  </div>
</div>
</template>
