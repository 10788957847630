<!-- views/ClassroomPage.vue -->
<template>
  <div class="classroom">
    <!-- Timer -->
    <div class="timer">{{ timer }}</div>

    <!-- Main Content Area -->
    <div class="content-area">
      <!-- Left Section: Presentation or Quiz -->
      <div class="left-section">
        <div class="presentation" v-if="!quizMode">
          <h2>Presentation</h2>
          <ul>
            <li v-for="(point, index) in keyPoints" :key="index">{{ point }}</li>
          </ul>
        </div>
        <div class="quiz" v-else>
          <h2>Quiz Time</h2>
          <p>Quiz content will go here!</p>
        </div>
      </div>

      <!-- Right Section: AI Assistant Chat -->
      <div class="right-section">
        <h2>AI Assistant</h2>
        <div class="chat-box">
          <p>{{ aiMessage }}</p>
          <button @click="nextContent">Next</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      timer: "00:00:00",
      keyPoints: ["Fractions Overview", "Simplifying Fractions", "Practice Examples"],
      aiMessage: "Welcome to your Math class! Let's start with Fractions.",
      quizMode: false,
      currentSlide: 0,
    };
  },
  methods: {
    nextContent() {
      // Move through the presentation or switch to quiz mode
      if (this.currentSlide < this.keyPoints.length - 1) {
        this.currentSlide++;
        this.aiMessage = `Here's the next key point: ${this.keyPoints[this.currentSlide]}`;
      } else {
        this.quizMode = true;
        this.aiMessage = "Great job! Now let's move to the quiz.";
      }
    },
    startTimer() {
      let seconds = 0;
      setInterval(() => {
        seconds++;
        const hours = String(Math.floor(seconds / 3600)).padStart(2, "0");
        const minutes = String(Math.floor((seconds % 3600) / 60)).padStart(2, "0");
        const secs = String(seconds % 60).padStart(2, "0");
        this.timer = `${hours}:${minutes}:${secs}`;
      }, 1000);
    },
  },
  mounted() {
    this.startTimer();
  },
};
</script>


<style>
.classroom {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

/* Timer */
.timer {
  text-align: center;
  background-color: #007bff;
  color: white;
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
}

/* Content Area */
.content-area {
  display: flex;
  flex: 1;
  overflow: hidden;
}

/* Left Section: Presentation/Quiz */
.left-section {
  flex: 2;
  padding: 20px;
  background-color: #f9f9f9;
  overflow-y: auto;
}

.presentation ul {
  list-style-type: disc;
  padding-left: 20px;
}

.quiz {
  text-align: center;
  font-size: 18px;
  color: #333;
}

/* Right Section: Chat */
.right-section {
  flex: 1;
  padding: 20px;
  background-color: #fff;
  border-left: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.chat-box {
  margin-top: auto;
  text-align: center;
}

button {
  margin-top: 15px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #218838;
}
</style>
